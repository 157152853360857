import { useQuery, UseQueryOptions } from "react-query";
import { getTranslations } from "../services/translation.service";
import { ITranslations } from "../interfaces/translations.interface";

export function useTranslation<T extends any = ITranslations>(
    options?: UseQueryOptions<any, unknown, T>
) {
    return useQuery({
        queryKey: ["translations"],
        queryFn: () => getTranslations().then((res) => res.data),
        retry: 1,
        refetchOnWindowFocus: false,
        staleTime: 15 * 60 * 1000,
        ...options
    });
}
