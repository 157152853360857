export interface IModulesResponse {
    data: {
        modules: IModule[];
    };
}
export interface IModuleResponse {
    data: {
        module: IModule;
    };
}

export interface IModule {
    id: string;
    title: string;
    description: string;
    contentAmount?: number;
    units?: IModuleUnit[];
}

export interface IUnitItem {
    type: ComponentTypes;
    id: string;
    title: string;
}

export interface IModuleUnit {
    id: string;
    title: string;
    type: ComponentTypes;
    items: IUnitItem[];
}

export enum ComponentTypes {
    VIDEO = "ComponentItemVideo",
    FORM = "ComponentItemTypeForm",
    RICH_TEXT = "ComponentItemRichText",
    IMAGE = "ComponentItemImage",
    FILE = "ComponentItemFile",
    AUDIO = "ComponentItemAudio"
}

export enum ItemTypes {
    AUDIO = "AUDIO",
    FILE = "FILE",
    IMAGE = "IMAGE",
    RICH_TEXT = "TEXT",
    FORM = "TYPEFORM",
    VIDEO = "VIDEO"
}

export enum ExternalPlatforms {
    VIMEO = "VIMEO",
    TYPEFORM = "TYPEFORM"
}

export interface IProgress {
    unitId: string;
    itemId: string;
    progress: number;
    itemType: ItemTypes;
    itemLocale: string;
    externalPlatform?: ExternalPlatforms;
    externalId?: string;
    externalData?: string;
}

export interface IModuleProgressRequest extends IProgress {
    moduleId: string;
}

export interface IModuleProgressResponse {
    id: string;
    employeeId: string;
    moduleId: string;
    unitId: string;
    itemId: string;
    itemType: string;
    itemLocale: string;
    externalPlatform: string;
    externalId: string;
    externalData: string;
    progress: number;
    timestamp: Date;
}
