import ComponentPicker from "../UnitComponents/ComponentPicker/ComponentPicker";
import { UnitCardProps } from "./UnitCard.interface";

function UnitCard({ id, title, components }: UnitCardProps) {
    return (
        <article className="col-12">
            <div className="pb-2">
                <h2 className="text-h2-smbd mb-1">{title}</h2>
                <hr className="is_sm" />
                {components.map((item, i) => (
                    <div className="p-3" key={i}>
                        <ComponentPicker unitId={id} {...item} isApp />
                    </div>
                ))}
            </div>
        </article>
    );
}

export default UnitCard;
