
import { useMutation } from "react-query";
import { getUserDetails, setUserSettings } from "../services/user.service";

export function useGetUser() {
    return useMutation(getUserDetails);
}

export function useSetUserSettings() {
    return useMutation(setUserSettings);
}