import { useContext, useEffect, useState } from "react";
import { CONTENT_BASE_URL } from "../../../shared/constants";
import { IAudioProps } from "./Audio.interface";
import { TranslationContext } from "../../../shared/providers/translation/translation.provider";
import { ModuleContext } from "../../../shared/providers/module/module.provider";
import { ItemTypes } from "../../../shared/interfaces/modules.interface";

function Audio(props: IAudioProps) {
    const { url, alt = "" } = props.audio;
    const { lang } = useContext(TranslationContext);
    const { setProgress } = useContext(ModuleContext);
    const [timestamp, setTimestamp] = useState<number>(10);
    const [percentage, setPercentage] = useState(0);
    const [currentTime, setCurrentTime] = useState(0);

    const handleVideoTimestamp = (data: any) => {
        const currentTime = (data.target as HTMLAudioElement).currentTime;
        const totalDuration = (data.target as HTMLAudioElement).duration;
        setCurrentTime(Math.round(currentTime));
        setPercentage(Math.floor((currentTime / totalDuration) * 100));
    };

    useEffect(() => {
        if (currentTime % 10 === 0 && lang) {
            console.log({ currentTime, percentage });
            setProgress({
                unitId: props.unitId,
                itemId: props.id,
                progress: percentage,
                itemType: ItemTypes.AUDIO,
                itemLocale: lang
            });
        }
    }, [currentTime, percentage]);

    return (
        <audio controls onTimeUpdate={handleVideoTimestamp}>
            <source src={`${CONTENT_BASE_URL}${url}#t=${timestamp}`} type="audio/mpeg" />
            Your browser does not support the audio element.
        </audio>
    );
}

export default Audio;
