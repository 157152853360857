import { Widget } from "@typeform/embed-react";
import { IFormProps } from "./Form.interface";
import { ModuleContext } from "../../../shared/providers/module/module.provider";
import { useContext } from "react";
import { TranslationContext } from "../../../shared/providers/translation/translation.provider";
import { ExternalPlatforms, ItemTypes } from "../../../shared/interfaces/modules.interface";

function Form(props: IFormProps) {
    const { formId } = props;
    const { lang } = useContext(TranslationContext);
    const { setProgress } = useContext(ModuleContext);

    const handleSubmit = (event: { formId: string; responseId: string }) => {
        if (!lang) return;

        setProgress({
            unitId: props.unitId,
            itemId: props.id,
            progress: 1,
            itemType: ItemTypes.FORM,
            itemLocale: lang,
            externalId: event.formId,
            externalPlatform: ExternalPlatforms.TYPEFORM,
            externalData: event.responseId
        });
    };

    if (!formId) return <p>No form id provided</p>;

    return (
        <Widget
            enableSandbox
            id={formId}
            className="typeform"
            onSubmit={handleSubmit}
            onQuestionChanged={(e) => console.log(e)}
            onStarted={(e) => console.log(e)}
        />
    );
}

export default Form;
