import { createBrowserRouter } from "react-router-dom";
import Styleguide from "../../pages/styleguide/Styleguide";
import Login, { LoginForm } from "../../pages/login/Login";
import { routes } from "./routes";
import RouteGuard from "../guards/route.guard";
import PasswordSetup from "../../pages/password-setup/PasswordSetup";
import Home from "../../pages/home/Home";
import Translations from "../../pages/translations/Translations";
import Account from "../../pages/account/Account";
import Faqs from "../../pages/faqs/Faqs";
import PasswordRecovery from "../../pages/password-recovery/PasswordRecovery";
import Contacts from "../../pages/contacts/Contacts";
import Modules from "../../pages/modules/Modules";
import Module from "../../pages/modules/single-module/Module";

const router = createBrowserRouter([
    {
        path: routes.LOGIN.path,
        element: <Login />,
        children: [
            {
                path: routes.LOGIN.path,
                element: <LoginForm />
            },
            {
                path: routes.FORGOT_PASSWORD.path,
                element: <PasswordRecovery />
            },
            {
                path: routes.PASSWORD_RECOVERY.path,
                element: <PasswordSetup />
            }
        ]
    },
    {
        path: routes.PASSWORD_RESET.path,
        element: <Login />,
        children: [
            {
                path: routes.PASSWORD_RESET.path,
                element: <PasswordSetup />
            }
        ]
    },
    {
        path: routes.HOME.path,
        element: <RouteGuard children={<Home />} />,
        children: [
            {
                path: routes.HOME.path,
                element: <Modules />
            },
            {
                path: routes.MODULE.path,
                element: <Module />
            },
            {
                path: routes.STYLEGUIDE.path,
                element: <Styleguide />
            },
            {
                path: routes.MY_ACCOUNT.path,
                element: <Account />
            },
            {
                path: routes.FAQS.path,
                element: <Faqs />
            },
            {
                path: routes.CONTACTS.path,
                element: <Contacts />
            }
        ]
    },
    {
        path: routes.TRANSLATIONS.path,
        element: <Translations />
    },
    {
        path: routes.PAGE_404.path,
        element: <p>404</p>
    },
    {
        path: "*",
        element: <p>404</p>
    }
]);

export default router;
