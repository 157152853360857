import { Link, useNavigate, useSearchParams } from "react-router-dom";
import InputText from "../../components/Forms/InputText/InputText";
import { routes } from "../../shared/router/routes";
import {
    IAuthTokens,
    IPasswordSetup,
    IRequestError
} from "../../shared/interfaces/authentication.interface";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useContext, useEffect, useState } from "react";
import { LoadingContext } from "../../shared/providers/loading/loading.provider";
import Success from "./../../shared/assets/imgs/success.svg";
import { TranslationContext } from "../../shared/providers/translation/translation.provider";
import Button from "../../components/Button/Button";
import { useFirstLogin, useRecoverPassword, useValidateCode, useValidateRecoveryCode } from "../../shared/hooks/useAuthentication";
import { AuthenticationContext } from "../../shared/providers/authentication/authentication.provider";

function PasswordSetup() {
    const { setAuthTokens, setIsAuthenticated } = useContext(AuthenticationContext);
    const { translate } = useContext(TranslationContext);
    const { setIsLoading } = useContext(LoadingContext);
    const [isSuccess, setIsSuccess] = useState<boolean | undefined>();
    const [errorCode, setErrorCode] = useState<string>();
    const [errorMessage, setErrorMessage] = useState<string>("");
    const validateCode = useValidateCode();
    const validateRecoveryCode = useValidateRecoveryCode();
    const setFirstLogin = useFirstLogin();
    const recoverPassword = useRecoverPassword();
    const navigate = useNavigate();
    const [query] = useSearchParams();

    const passwordRequirements = [
        {
            label: "Required",
            test: (v: string) => !v,
            display: false
        },
        {
            label: "Between 8 and 64 characters",
            test: (v: string) => v.length < 8 || v.length > 64,
            display: true
        },
        {
            label: "At least one number",
            test: (v: string) => !/[0-9]/.test(v),
            display: true
        },
        {
            label: "At least one uppercase letter",
            test: (v: string) => !/[A-Z]/.test(v),
            display: true
        },
        {
            label: "At least one special character (!, @, #, $, %, ^, &, *)",
            test: (v: string) => !/[!@#$%^&*]/.test(v),
            display: true
        },
        {
            label: "At least one lowercase letter",
            test: (v: string) => !/[a-z]/.test(v),
            display: true
        }
    ];

    const form = useFormik({
        initialValues: {
            password: "",
            confirmPassword: ""
        },
        validationSchema: Yup.object().shape({
            password: Yup.string().test("password-rules", "Invalid password", function (val) {
                const errors: string[] = [];
                const value = val ?? "";

                passwordRequirements.forEach((r) => {
                    if (r.test(value)) errors.push(r.label);
                });

                if (errors.length) {
                    return this.createError({
                        message: errors.join("/")
                    });
                }

                return true;
            }),
            confirmPassword: Yup.string()
                .required("Confirm password please")
                .test("confirm-password", "Passwords do not match", function (val) {
                    return this.parent.password === val;
                })
        }),
        validateOnChange: true,
        onSubmit: async (values: IPasswordSetup) => {
            setIsLoading(true);
            const code = query.get("code");
            const recoveryCode = query.get("recovery");
            let response = null;

            if (code) {
                response = await setFirstLogin.mutateAsync({
                    activationCode: code,
                    password: values.password
                });
            } else if (recoveryCode) {
                response = await recoverPassword.mutateAsync({
                    recoveryCode: recoveryCode,
                    newPassword: values.password
                });
            }

            if (!response?.success) {
                const data = response?.data as IRequestError;
                setErrorCode(data.code);
                setErrorMessage(data.message);
                setIsLoading(false);
                return;
            }

            if (code) {
                const data = response?.data as IAuthTokens;
                setAuthTokens(data);
                setIsAuthenticated(true);
                navigate(routes.HOME.path);
            } else if (recoveryCode) {
                navigate(routes.LOGIN.path);
            }

            setIsLoading(false);
        }
    });

    const passwordLevels = ["", "Very Weak", "Weak", "Medium", "Good", "Very Good"];

    const getCurrentPasswordState = (): number => {
        return form.dirty
            ? Number(passwordLevels.length - 1 - (form.errors.password?.split("/").length ?? 0))
            : 0;
    };

    const checkRequirement = (label: string) => {
        return form.errors.password?.split("/").some((lb) => lb === label);
    };

    const validateActivationCode = async (code: string) => {
        const response = await validateCode.mutateAsync(code);

        if (!response.success) {
            const data = response.data as IRequestError;
            setErrorCode(data.message);
            setErrorMessage("Invalid activation code");
        }
    };

    const validateRecovery = async (code: string) => {
        const response = await validateRecoveryCode.mutateAsync(code);

        if (!response.success) {
            const data = response.data as IRequestError;
            setErrorCode(data.message);
            setErrorMessage("Invalid recovery code");
        }
    };

    useEffect(() => {
        const code = query.get("code");
        const recoveryCode = query.get("recovery");

        if (code) validateActivationCode(code);
        else if (recoveryCode) validateRecovery(recoveryCode);
    }, [query]);

    return (
        <>
            {isSuccess === undefined && (
                <div className="d-flex flex-column gap-5">
                    <div className="d-flex flex-column gap-2">
                        <h1 className="text-h1-big-bd text-h1-big-bd-xl color-neutrals-darker">
                            {translate("page.password_setup.title", "Define your password")}
                        </h1>
                        <p className="text-bd1-rg color-neutrals-darker">
                            {translate(
                                "page.password_setup.description",
                                "Sit libero fugiat hic laboriosam in molestiae incidunt nesciunt. Saepe omnis eos accusantium"
                            )}
                        </p>
                    </div>
                    <form className="form d-flex flex-column gap-22" onSubmit={form.handleSubmit}>
                        {errorCode && (
                            <span className="mt-n02 color-red-600 text-bd4-md mb-n1">
                                {translate(`auth.code.${errorCode}`, errorMessage)}
                            </span>
                        )}
                        <InputText
                            name="password"
                            label={translate("common.password", "Password")}
                            placeholder={translate("form.placeholder.password", "Insert password")}
                            type="password"
                            customCSS={form.errors.password ? "has_error" : ""}
                            currentStep={getCurrentPasswordState()}
                            totalSteps={
                                form.errors.password ? passwordLevels.length - 1 : undefined
                            }
                            stepLabel={passwordLevels[getCurrentPasswordState()]}
                            onChange={form.handleChange}
                        />
                        {form.errors.password && (
                            <div className="form_field mt-n12">
                                <ul className="form_field-requirements">
                                    {passwordRequirements.map(
                                        (req, i) =>
                                            req.display && (
                                                <li
                                                    className="text-bd3-md d-flex gap-02 align-items-start"
                                                    key={i}>
                                                    <i
                                                        className={`icon icon-16 ${
                                                            checkRequirement(req.label)
                                                                ? "icon-misuse color-red-600"
                                                                : "icon-checkmark--filled color-green-600"
                                                        }`}></i>
                                                    {req.label}
                                                </li>
                                            )
                                    )}
                                </ul>
                            </div>
                        )}
                        <InputText
                            name="confirmPassword"
                            label={translate("form.confirm_password", "Confirm password")}
                            placeholder={translate(
                                "form.placeholder.confirm_password",
                                "Confirm password"
                            )}
                            type="password"
                            errors={
                                form.submitCount > 0 ? form.errors.confirmPassword?.split("/") : []
                            }
                            onChange={form.handleChange}
                        />
                        <div className="d-flex flex-column gap-1 mt-12">
                            <Button
                                type="submit"
                                variant="solid"
                                label={translate("common.save_changes", "Save changes")}
                            />
                            <Link
                                to={routes.LOGIN.path}
                                className="color-green-600 text-bd2-bd text-center">
                                {translate("page.login.go_to_login", "Go to login")}
                            </Link>
                        </div>
                    </form>
                </div>
            )}
            {!isSuccess && isSuccess !== undefined && (
                <div className="d-flex flex-column justify-content-center align-items-center mt-5 text-center gap-12">
                    <img src={Success} alt="" aria-hidden="true" />
                    <h1 className="text-h1-md color-neutrals-darker px-5 mx-5">
                        {translate("common.generic_error", "There was an error")}
                    </h1>
                    <span className="text-bd1-rg">
                        {translate("common.retry", "Please try again.")}
                    </span>
                    <button
                        onClick={() => setIsSuccess(undefined)}
                        className="button bg-green-500 color-neutrals-black text-bd2-bd w-100 mt-32">
                        {translate("page.login.change_password", "Change password")}
                    </button>
                </div>
            )}
            {isSuccess && (
                <div className="d-flex flex-column justify-content-center align-items-center mt-5 text-center gap-12">
                    <img src={Success} alt="" aria-hidden="true" />
                    <h1 className="text-h1-md color-neutrals-darker px-5 mx-5">
                        {translate("page.login.password_success", "Password set successfully!")}
                    </h1>
                    <span className="text-bd1-rg">
                        {translate(
                            "page.login.password_success_description",
                            "Now please log in to continue."
                        )}
                    </span>
                    <Link
                        to={routes.LOGIN.path}
                        className="button bg-green-500 color-neutrals-black text-bd2-bd w-100 mt-32">
                        {translate("common.login", "Login")}
                    </Link>
                </div>
            )}
        </>
    );
}

export default PasswordSetup;
