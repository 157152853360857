import { useContext, useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Logo from "../../components/Logo/Logo";
import LoginThumbnail from "./../../shared/assets/imgs/login_thumbnail.png";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { routes } from "../../shared/router/routes";
import { AuthenticationContext } from "../../shared/providers/authentication/authentication.provider";
import InputText from "../../components/Forms/InputText/InputText";
import { LoadingContext } from "../../shared/providers/loading/loading.provider";
import {
    IAuthTokens,
    ILogin,
    IRequestError
} from "../../shared/interfaces/authentication.interface";
import { getCookies } from "../../shared/helpers/utils";
import { COOKIES } from "../../shared/constants";
import { useAuthLogin } from "../../shared/hooks/useAuthentication";
import GlobalLayout from "../../components/GlobalLayout/GlobalLayout";
import Button from "../../components/Button/Button";
import { TranslationContext } from "../../shared/providers/translation/translation.provider";
import LanguagePicker from "../../components/LanguagePicker/LanguangePicker";

function Login() {
    const navigate = useNavigate();
    const { setIsAuthenticated } = useContext(AuthenticationContext);

    useEffect(() => {
        document.body.classList.add("is_login");

        const cookies = getCookies();
        if (cookies[COOKIES.REFRESH_TOKEN]) {
            navigate(routes.HOME.path);
        } else {
            setIsAuthenticated(false);
        }

        return () => {
            document.body.classList.remove("is_login");
        };
    }, []);

    return (
        <GlobalLayout>
            <div className="login d-flex gap-22 justify-content-center align-items-start">
                <Logo />
                <div className="login_form py-5 my-53">
                    <Outlet />
                    <div className="mt-5 d-flex flex-column gap-3">
                        <hr />
                        <div className="mx-auto">
                            <LanguagePicker isInline />
                        </div>
                    </div>
                </div>
                <figure className="login_thumbnail" aria-hidden="true">
                    <img src={LoginThumbnail} alt="" aria-hidden="true" />
                </figure>
            </div>
        </GlobalLayout>
    );
}

export function LoginForm() {
    const login = useAuthLogin();
    const navigate = useNavigate();
    const { setAuthTokens, setIsAuthenticated } = useContext(AuthenticationContext);
    const { setIsLoading } = useContext(LoadingContext);
    const { translate } = useContext(TranslationContext);
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [errorCode, setErrorCode] = useState<string>();

    const form = useFormik({
        initialValues: {
            email: "",
            password: ""
        },
        validationSchema: Yup.object().shape({
            email: Yup.string().required("Email is required"),
            password: Yup.string().required("Password is required")
        }),
        validateOnChange: false,
        onSubmit: async (values: ILogin) => {
            setIsLoading(true);
            const response = await login.mutateAsync(values);

            if (response.success) {
                const data = response.data as IAuthTokens;
                setAuthTokens(data);
                setIsAuthenticated(true);
                navigate(routes.HOME.path);
            } else {
                const data = response.data as IRequestError;
                setErrorCode(data.code);
                setErrorMessage(data.message);
                setIsAuthenticated(false);
            }
            setIsLoading(false);
        }
    });

    return (
        <div className="d-flex flex-column gap-5">
            <div className="d-flex flex-column gap-2">
                <h1 className="text-h1-big-bd text-h1-big-bd-xl color-neutrals-darker">
                    {translate("page.login.title", "Welcome to Prosperh")}
                </h1>
                <p className="text-bd1-rg color-neutrals-darker">
                    {translate(
                        "page.login.description",
                        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Impedit praesentium, quos minima dicta architecto nulla fugit repellat reiciendis est nisi."
                    )}
                </p>
            </div>
            <form className="form d-flex flex-column gap-22" onSubmit={form.handleSubmit}>
                {errorCode && (
                    <span className="mt-n02 color-red-600 text-bd4-md mb-n1">
                        {translate(`auth.code.${errorCode}`, errorMessage)}
                    </span>
                )}
                <InputText
                    name="email"
                    label={translate("common.email", "Email")}
                    placeholder={translate("form.placeholder.email", "Insert email")}
                    type="email"
                    errors={form.submitCount > 0 ? form.errors.email?.split("#") : []}
                    onChange={form.handleChange}
                />
                <InputText
                    name="password"
                    label={translate("common.password", "Password")}
                    placeholder={translate("form.placeholder.password", "Insert password")}
                    type="password"
                    errors={form.submitCount > 0 ? form.errors.password?.split("#") : []}
                    onChange={form.handleChange}
                />
                <div className="d-flex flex-column gap-1 mt-12">
                    <Button type="submit" variant="solid" label={translate("common.login", "Login")} />
                    <Link
                        to={routes.FORGOT_PASSWORD.path}
                        className="color-green-600 text-bd2-bd text-center">
                        {translate("page.login.forgot_password", "Forgot your password?")}
                    </Link>
                </div>
            </form>
        </div>
    );
}

export default Login;
