import { ITextareaProps } from "./Textarea.interface";
import { ChangeEvent, useEffect, useState } from "react";

function Textarea({
    label,
    value: inputValue,
    placeholder,
    name,
    onChange: onInputChange,
    errors,
    customCSS,
    ...props
}: ITextareaProps) {
    const [value, setValue] = useState<string | number | readonly string[]>("");

    useEffect(() => {
        if (!inputValue && inputValue !== "") return;

        setValue(inputValue);
    }, [inputValue]);

    const onChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        if (onInputChange) onInputChange(e);
        setValue(e.target.value);
    };

    return (
        <label className="form_field">
            {label && <span className="form_field-label">{label}</span>}
            <div className="form_field-actions_wrapper">
                <textarea
                    {...props}
                    value={value}
                    className={`form_field-textarea ${errors?.length ? "has_error" : ""} ${customCSS}`}
                    placeholder={placeholder}
                    name={name}
                    onChange={onChange}></textarea>
            </div>
            {errors &&
                errors.map((error, i) => (
                    <span key={i} className="mt-n02 color-red-600 text-bd4-md">
                        {error}
                    </span>
                ))}
        </label>
    );
}

export default Textarea;
