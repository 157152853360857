import { APP_CONFIGS } from "../constants";
import {
  IRequest,
  IRequestError,
} from "../interfaces/authentication.interface";
import { IUser, IUserSettings } from "../interfaces/user.interface";
import { request } from "./requests.service";

export const getUserDetails = async (): Promise<
  IRequest<IUser | IRequestError>
> => {
  try {
    const response = await request.get<IUser>(`${APP_CONFIGS.api.base}`);
    return { success: true, data: response.data as IUser };
  } catch (error: any) {
    return { success: false, data: error as IRequestError };
  }
};

export const setUserSettings = async (
    setting: IUserSettings
): Promise<IRequest<IUser | IRequestError>> => {
    try {
        const response = await request.put<IUser>(`${APP_CONFIGS.api.base}/settings`, setting);
        return { success: true, data: response.data as IUser };
    } catch (error: any) {
        return { success: false, data: error as IRequestError };
    }
};
