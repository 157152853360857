import { useContext } from "react";
import { CONTENT_BASE_URL } from "../../../shared/constants";
import { ModuleContext } from "../../../shared/providers/module/module.provider";
import { IImageProps } from "./Image.interface";
import { TranslationContext } from "../../../shared/providers/translation/translation.provider";
import { ItemTypes } from "../../../shared/interfaces/modules.interface";
import Button from "../../Button/Button";

function Image({ image, unitId, id }: IImageProps) {
    const { url, alt = "", width, height } = image;
    const { setProgress } = useContext(ModuleContext);
    const { lang } = useContext(TranslationContext);

    const handleProgress = () => {
        if (lang && unitId) {
            setProgress({
                unitId: unitId,
                itemId: id,
                progress: 1,
                itemType: ItemTypes.IMAGE,
                itemLocale: lang
            });
        }
    };

    return (
        <>
            <img src={`${CONTENT_BASE_URL}${url}`} alt={alt} width={width} height={height} />
            <Button type="button" onClick={handleProgress} label="Seen?" variant="solid" />
        </>
    );
}

export default Image;
