import { Outlet } from "react-router-dom";
import GlobalLayout from "../../components/GlobalLayout/GlobalLayout";

function Home() {
    return (
        <GlobalLayout>
            <Outlet />
        </GlobalLayout>
    );
}

export default Home;
