import { createContext, useEffect, useState } from "react";
import { IModuleContext, IModuleProps } from "./module.interface";
import { IModule, IModuleProgressRequest, IProgress } from "../../interfaces/modules.interface";
import { useSetModuleProgress } from "../../hooks/useModules";

const moduleInitValues: IModuleContext = {
    progress: undefined,
    setProgress: () => {},
    module: undefined,
    setModule: () => {}
};

export const ModuleContext = createContext<IModuleContext>(moduleInitValues);

const ModuleProvider = ({ children }: IModuleProps) => {
    const [progress, setProgress] = useState<IProgress>();
    const [module, setModule] = useState<IModule>();
    const updateProgress = useSetModuleProgress();

    useEffect(() => {
        if (!module?.id) return;
        console.log("progress updating...", progress);

        updateProgress.mutateAsync({ ...(progress as IProgress), moduleId: module?.id });
    }, [progress]);

    return (
        <ModuleContext.Provider value={{ progress, setProgress, module, setModule }}>
            {children}
        </ModuleContext.Provider>
    );
};

export default ModuleProvider;
