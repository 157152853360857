import { useQuery, UseQueryOptions } from "react-query";
import { IFaqSection } from "../interfaces/faqs.interface";
import { getFaqs } from "../services/faqs.service";

export function useFaqs<T extends any = IFaqSection[]>(
    locale: string,
    options?: UseQueryOptions<any, unknown, T>
) {
    return useQuery({
        queryKey: ["faqs", locale],
        queryFn: () => getFaqs(locale),
        retry: 1,
        refetchOnWindowFocus: false,
        staleTime: 15 * 60 * 1000,
        ...options
    });
}
