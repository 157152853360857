import { IModuleCardProps } from "./ModuleCard.interface";
import Placeholder from "./../../shared/assets/imgs/placeholder.svg";
import { Link } from "react-router-dom";
import { routes } from "../../shared/router/routes";
import { useContext } from "react";
import { TranslationContext } from "../../shared/providers/translation/translation.provider";
import FavoriteButton from "../FavoriteButton/FavoriteButton";

function ModuleCard({ title, contentAmount, id }: IModuleCardProps) {
    const { translate } = useContext(TranslationContext);

    return (
        <article className="card card_module d-flex flex-column gap-1">
            <Link to={routes.MODULE.path.replace(":id", id)}>
                <figure className="card_thumbnail">
                    <img src={Placeholder} alt={title} />
                </figure>
            </Link>
            <div className="card_info px-1 d-flex flex-column gap-1">
                <Link
                    to={routes.MODULE.path.replace(":id", id)}
                    className="d-flex flex-column gap-1">
                    <h2 className="text-bd2-rg color-neutrals-black">{title}</h2>
                    <div className="my-01 d-flex gap-02 align-items-center">
                        <progress className="card_progress" value="50" max="100"></progress>
                        <span className="text-bd4-md color-neutrals-neutral">50%</span>
                    </div>
                </Link>
                <div className="d-flex justify-content-between gap-1 py-1">
                    {!!contentAmount && (
                        <span className="text-bd2-rg color-neutrals-neutral">
                            {`${contentAmount} ${
                                contentAmount > 1
                                    ? translate("common.contents", "contents")
                                    : translate("common.content", "content")
                            }`}
                        </span>
                    )}
                    <FavoriteButton
                        onClick={() => {
                            console.log("set module as favorite");
                        }}
                    />
                </div>
            </div>
        </article>
    );
}

export default ModuleCard;
