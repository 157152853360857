import { useContext, useEffect, useState } from "react";
import { TranslationContext } from "../../shared/providers/translation/translation.provider";
import InfoCard from "../../components/InfoCard/InfoCard";
import InputText from "../../components/Forms/InputText/InputText";
import Textarea from "../../components/Forms/Textarea/Textarea";
import Button from "../../components/Button/Button";
import Select from "../../components/Forms/Select/Select";
import { useFormik } from "formik";
import { LoadingContext } from "../../shared/providers/loading/loading.provider";
import Success from "./../../shared/assets/imgs/success.svg";
import { useGetSubjects } from "../../shared/hooks/useContactForm";
import { DEFAULT_LANGUAGE } from "../../shared/constants";
import { ISelectOption } from "../../components/Forms/Select/Select.interface";
import { IRequest } from "../../shared/interfaces/authentication.interface";

function Contacts() {
    const { translate, lang } = useContext(TranslationContext);
    const { setIsLoading } = useContext(LoadingContext);
    const [isSuccess, setIsSuccess] = useState<boolean>();
    const { data: subjects, isLoading } = useGetSubjects<IRequest<ISelectOption[]>>(lang ?? DEFAULT_LANGUAGE);

    useEffect(() => {
        setIsLoading(isLoading);
    }, [isLoading, setIsLoading]);

    const form = useFormik({
        initialValues: {
            subject: "",
            title: "",
            message: ""
        },
        onSubmit: () => {
            setIsLoading(true);
            setTimeout(() => {
                const isError = Math.random() < 0.5;
                setIsSuccess(isError);
                setIsLoading(false);
                form.resetForm();
            }, 2000);
        }
    });

    return (
        <div className="contacts">
            <h1 className="text-h2-smbd mb-3">
                {translate("page.contacts.title", "Contact & feedback")}
            </h1>
            {isSuccess === undefined && (
                <div className="row g-3 g-xl-5">
                    <div className="col-12 col-lg-5">
                        <InfoCard
                            title={translate("common.support", "Support")}
                            text={translate(
                                "page.contacts.support_description",
                                "Please fill out the following form and we will get in touch as soon as possible."
                            )}
                        />
                    </div>
                    <div className="col-12 col-lg-7 offset-xl-1">
                        <form
                            className="form d-flex flex-column gap-22"
                            onSubmit={form.handleSubmit}>
                            <Select
                                value={form.values.subject}
                                onChange={(option) => form.setFieldValue("subject", option.value)}
                                label={translate("page.contacts.subject_label", "Select a suject")}
                                placeholder="Select option"
                                options={subjects?.data ?? []}
                            />
                            <InputText
                                type="text"
                                label={translate("common.title", "Title")}
                                name="title"
                                value={form.values.title}
                                onChange={form.handleChange}
                                placeholder={translate("page.contacts.label.title", "Write title")}
                            />
                            <Textarea
                                label={translate("page.contacts.label.message", "Your message")}
                                name="message"
                                value={form.values.message}
                                onChange={form.handleChange}
                                placeholder={translate(
                                    "page.contacts.label.message",
                                    "Your message"
                                )}
                            />
                            <Button
                                type="submit"
                                label={translate(
                                    "page.contacts.label.submit",
                                    "Submit your request"
                                )}
                                variant="solid"
                                icon="send-1"
                                customCss="me-auto"
                            />
                        </form>
                    </div>
                </div>
            )}
            {!isSuccess && isSuccess !== undefined && (
                <div className="d-flex flex-column justify-content-center align-items-center mt-5 text-center gap-12">
                    <img src={Success} alt="" aria-hidden="true" />
                    <h1 className="text-h1-md color-neutrals-darker px-5 mx-5">
                        {translate("common.generic_error", "There was an error")}
                    </h1>
                    <span className="text-bd1-rg">
                        {translate("common.retry", "Please try again.")}
                    </span>
                    <button
                        onClick={() => setIsSuccess(undefined)}
                        className="button bg-green-500 color-neutrals-black text-bd2-bd mx-auto mt-32">
                        {translate("common.retry", "Please try again.")}
                    </button>
                </div>
            )}
            {isSuccess && (
                <div className="d-flex flex-column justify-content-center align-items-center mt-5 text-center gap-12">
                    <img
                        src={Success}
                        alt=""
                        aria-hidden="true"
                        style={{ transform: "scale(-1,-1)" }}
                    />
                    <h1 className="text-h1-md color-neutrals-darker px-5 mx-5">
                        {translate("page.contact.success_title", "Request submitted sucessfully")}
                    </h1>
                    <span className="text-bd1-rg">
                        {translate(
                            "page.contact.success_description",
                            "We will get in touch as soon as possible."
                        )}
                    </span>
                </div>
            )}
        </div>
    );
}

export default Contacts;
