import { useContext, useEffect } from "react";
import { DEFAULT_LANGUAGE } from "../../../shared/constants";
import { useGetModule } from "../../../shared/hooks/useModules";
import { TranslationContext } from "../../../shared/providers/translation/translation.provider";
import { IModule } from "../../../shared/interfaces/modules.interface";
import { IModuleProps } from "./Module.interface";
import { useNavigate, useParams } from "react-router-dom";
import { routes } from "../../../shared/router/routes";
import { LoadingContext } from "../../../shared/providers/loading/loading.provider";
import UnitCard from "../../../components/UnitCard/UnitCard";
import { ModuleContext } from "../../../shared/providers/module/module.provider";

function Module(props: IModuleProps) {
    const navigate = useNavigate();
    const { lang } = useContext(TranslationContext);
    const { setIsLoading } = useContext(LoadingContext);
    const { setModule } = useContext(ModuleContext);
    const { id } = useParams();
    const { isLoading, data: module } = useGetModule<IModule>(id, lang ?? DEFAULT_LANGUAGE);

    useEffect(() => {
        setIsLoading(isLoading);
        if (isLoading) return;
        if (!module) navigate(routes.MODULES.path);

        setModule(module);        
    }, [isLoading, module, navigate, setIsLoading]);

    return (
        <>
            <div className="row gx-1 gy-2">
                <div className="col-12">
                    <h1 className="text-h1-bd">{module?.title}</h1>
                </div>
                {module?.units?.map((unit, i) => (
                    <UnitCard key={i} {...unit} title={unit.title} components={unit.items} />
                ))}
            </div>
        </>
    );
}

export default Module;
