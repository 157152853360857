import { useQuery, UseQueryOptions } from "react-query";
import { getSubjects } from "../services/contactForm.service";
import { ISelectOption } from "../../components/Forms/Select/Select.interface";

export function useGetSubjects<T extends any = ISelectOption[]>(
    locale: string,
    options?: UseQueryOptions<any, unknown, T>
) {
    return useQuery({
        queryKey: ["subjects", locale],
        queryFn: () => getSubjects(locale),
        retry: 1,
        refetchOnWindowFocus: false,
        staleTime: 15 * 60 * 1000,
        ...options
    });
}
