import { useContext, useEffect } from "react";
import { IGlobalLayoutProps } from "./GlobalLayout.interface";
import { AuthenticationContext } from "../../shared/providers/authentication/authentication.provider";
import { LoadingContext } from "../../shared/providers/loading/loading.provider";
import Loading from "../Loading/Loading";
import Navbar from "../Navbar/Navbar";
import Header from "../Header/Header";
import { APP_CONFIGS } from "../../shared/constants";

function GlobalLayout({ children }: IGlobalLayoutProps) {
    const { isAuthenticated } = useContext(AuthenticationContext);
    const { isLoading } = useContext(LoadingContext);

    useEffect(() => {
        document.title = APP_CONFIGS.title;
    }, []);

    return (
        <>
            {isAuthenticated && (
                <div className="layout">
                    <Navbar />
                    <Header />
                    {isLoading && <Loading />}
                    <main className="content px-3 pt-12 pb-5 mb-5">{children}</main>
                </div>
            )}
            {!isAuthenticated && (
                <>
                    {isLoading && <Loading />}
                    {children}
                </>
            )}
        </>
    );
}

export default GlobalLayout;
