import { useContext, useEffect, useRef, useState } from "react";
import {
    ILanguagePickerOptionsProps,
    ILanguagePickerProps
} from "./LanguagePicker.interface";
import { TranslationContext } from "../../shared/providers/translation/translation.provider";
import { LOCALE_LABELS } from "../../shared/constants";
import { delay } from "../../shared/helpers/utils";
import { IDropdownPlacement } from "../Dropdown/Dropdown.interface";

function LanguagePicker(props: ILanguagePickerProps) {
    const DEFAULT_PLACEMENT = "bottom right";
    const DEFAULT_OFFSET = 300;

    const { lang, translate } = useContext(TranslationContext);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [placement, setPlacement] = useState<IDropdownPlacement | undefined>();
    const pickerElRef = useRef<HTMLDivElement>(null);
    const optionsElRef = useRef<HTMLUListElement>(null);

    useEffect(() => {
        if (!optionsElRef.current || !isOpen) return;

        const coords = optionsElRef.current?.getBoundingClientRect();
        let place = "";

        if (coords.top < 0) place += "bottom";
        else if (coords.top > 0 && coords.bottom + DEFAULT_OFFSET >= window.innerHeight)
            place += "top";

        if (coords.left >= 0 && coords.right + DEFAULT_OFFSET >= window.innerWidth)
            place += " right";
        else place += " left";

        if (place === "") place = DEFAULT_PLACEMENT;

        setPlacement(place as IDropdownPlacement);
    }, [isOpen, window, optionsElRef.current]);

    return (
        <div className={`form_field-select lang_picker ${props.isInline ? "is_inline" : ""}`} ref={pickerElRef} >
            {!props.isInline && (
                <button
                    type="button"
                    aria-label={`${translate("common.current_language", "Current language")}: ${lang ? LOCALE_LABELS[lang] : ""}`}
                    className={`select-btn lang_picker-btn ${isOpen ? "is_active" : ""}`}
                    aria-pressed={isOpen}
                    onClick={() => setIsOpen((v) => !v)}>
                    {lang?.toUpperCase()}
                    <i className="select-icon"></i>
                </button>
            )}
            {props.isInline && (
                <button
                    type="button"
                    aria-label={`${translate("common.current_language", "Current language")}: ${lang ? LOCALE_LABELS[lang] : ""}`}
                    className={`select-btn lang_picker-btn p-0 m-0 bg-transparent ${isOpen ? "is_active" : ""}`}
                    aria-pressed={isOpen}
                    onClick={() => setIsOpen((v) => !v)}>
                    <i className="icon icon-24 icon-globe"></i>
                    {`${translate("common.change_language", "Change language")} (${lang?.toUpperCase()})`}
                </button>
            )}
            {isOpen && (
                <LanguagePickerOptions
                    setIsOpen={setIsOpen}
                    placement={placement}
                    dropdownRef={optionsElRef}
                    selectRef={pickerElRef}
                />
            )}
        </div>
    );
}

function LanguagePickerOptions({ setIsOpen, placement, dropdownRef, selectRef }: ILanguagePickerOptionsProps) {
    const { lang, setLang } = useContext(TranslationContext);

    useEffect(() => {
        function clickOutside(e: Event) {
            const clickedEl = e.target as HTMLElement;
            if (selectRef.current?.contains(clickedEl)) return;
            setIsOpen(false);
        }

        async function onBlur() {
            await delay(100);
            if (selectRef.current?.contains(document.activeElement)) return;
            setIsOpen(false);
        }

        window.addEventListener("keydown", onBlur);
        window.addEventListener("click", clickOutside);

        return () => {
            window.removeEventListener("click", clickOutside);
            window.removeEventListener("keydown", onBlur);
        };
    }, [selectRef, setIsOpen]);

    return (
        <ul
            className={`select-options lang_picker-options ${placement ? placement : "opacity-0"}`}
            ref={dropdownRef}>
            {Object.entries(LOCALE_LABELS).map(([key, label]) => (
                <li aria-current={lang === key} key={key}>
                    <button
                        type="button"
                        className={`${lang === key ? "is_active" : ""}`}
                        onClick={() => setLang(key)}>
                        {label}
                    </button>
                </li>
            ))}
        </ul>
    );
}

export default LanguagePicker;
