import { useContext } from "react";
import { AuthenticationContext } from "../../shared/providers/authentication/authentication.provider";
import { IProfileCardProps } from "./ProfileCard.interface";
import { useNavigate } from "react-router-dom";
import { routes } from "../../shared/router/routes";
import Dropdown from "../Dropdown/Dropdown";
import { TranslationContext } from "../../shared/providers/translation/translation.provider";
import Button from "../Button/Button";
import { logout } from "../../shared/services/authentication.service";
import Link from "../Link/Link";

function ProfileCard(props: IProfileCardProps) {
    const { user, setIsAuthenticated } = useContext(AuthenticationContext);
    const { translate } = useContext(TranslationContext);
    const navigate = useNavigate();
    const onLogout = () => {
        logout().then((_) => {
            setIsAuthenticated(false);
            navigate(routes.LOGIN.path);
        });
    };
    return (
        <Dropdown
            buttonContent={
                <div className="profile">
                    <figure className="profile_thumbnail">
                        <img
                            src="https://avatar.iran.liara.run/public"
                            style={{ width: "150%", translate: "-15% -8%" }}
                            alt={user?.name}
                        />
                    </figure>
                    <div className="profile_info">
                        <span className="text-bd3-rg color-neutrals-black">{user?.email}</span>
                        <span className="text-bd3-light color-neutrals-neutral">HR Manager</span>
                    </div>
                </div>
            }
            dropdownContent={
                <div className="d-flex flex-column gap-1 p-1">
                    <Link
                        customCss="p-02 d-flex gap-02 align-items-center justify-content-start color-neutrals-black text-bd2-md"
                        to={routes.MY_ACCOUNT.path}
                        icon="user"
                        label={translate("common.account", "Account")}
                    />
                    <hr className="is_sm" />
                    <Button
                        type="button"
                        customCss="p-02 d-flex gap-02 align-items-center justify-content-start color-neutrals-black text-bd2-md"
                        icon="exit"
                        onClick={onLogout}
                        label={translate("common.logout", "Logout")}
                    />
                </div>
            }
        />
    );
}

export default ProfileCard;
