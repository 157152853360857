import { useContext, useEffect, useRef, useState } from "react";
import { IVideoProps, IVimeoVideo } from "./Video.interface";
import Player from "@vimeo/player";
import { ModuleContext } from "../../../shared/providers/module/module.provider";
import { ExternalPlatforms, ItemTypes } from "../../../shared/interfaces/modules.interface";
import { TranslationContext } from "../../../shared/providers/translation/translation.provider";

function Video(props: IVideoProps) {
    const { lang } = useContext(TranslationContext);
    const { setProgress } = useContext(ModuleContext);
    const [videoId, setVideoId] = useState<string>();
    const [timestamp, setTimestamp] = useState<string>("10");
    const [currentTime, setCurrentTime] = useState(0);
    const [percentage, setPercentage] = useState(0);
    const iframe = useRef<HTMLIFrameElement>(null);

    useEffect(() => {
        if (!iframe) return;

        const player = new Player(iframe.current as HTMLIFrameElement);
        const handleVideoTimestamp = (data: IVimeoVideo) => {
            setPercentage(Math.floor(data.percent * 100));
            setCurrentTime(Math.round(data.seconds));
        };

        player.on("timeupdate", handleVideoTimestamp);

        return () => {
            player.off("timeupdate", handleVideoTimestamp);
        };
    }, []);

    useEffect(() => {
        // UPDATE DB EVERY 10s
        if (currentTime % 10 === 0 && lang && videoId) {
            console.log({ currentTime, percentage });
            setProgress({
                unitId: props.unitId,
                itemId: props.id,
                progress: percentage,
                itemType: ItemTypes.VIDEO,
                itemLocale: lang,
                externalPlatform: ExternalPlatforms.VIMEO,
                externalId: videoId
            });
        }
    }, [currentTime, percentage]);

    useEffect(() => {
        const params = props.url.split("/").pop();
        const [id, timestamp] = params?.split("#t=") ?? [];

        setVideoId(id);
        if (timestamp) setTimestamp(timestamp.replace(/\D/g, ""));
    }, [props.url]);

    return (
        <iframe
            ref={iframe}
            title={props.title}
            src={`https://player.vimeo.com/video/${videoId}#t=${timestamp}s`}
            width="640"
            height="360"
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen></iframe>
    );
}

export default Video;
