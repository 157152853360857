import { useContext, useEffect, useState } from "react";
import { IFaqsProps } from "./Faqs.interface";
import { TranslationContext } from "../../shared/providers/translation/translation.provider";
import InputText from "../../components/Forms/InputText/InputText";
import { useFormik } from "formik";
import Accordion from "../../components/Accordion/Accordion";
import { useFaqs } from "../../shared/hooks/useFaqs";
import { DEFAULT_LANGUAGE } from "../../shared/constants";
import { IFaqSection } from "../../shared/interfaces/faqs.interface";
import { LoadingContext } from "../../shared/providers/loading/loading.provider";
import { IRequest } from "../../shared/interfaces/authentication.interface";

function Faqs(props: IFaqsProps) {
    const { setIsLoading } = useContext(LoadingContext);
    const { lang } = useContext(TranslationContext);
    const { data, isLoading } = useFaqs<IRequest<IFaqSection[]>>(lang ?? DEFAULT_LANGUAGE);

    const [faqs, setFaqs] = useState<IFaqSection[]>([]);

    useEffect(() => {
        setIsLoading(isLoading);

        if (!data) return;
        if (data.success) setFaqs(data.data);
    }, [data, isLoading, setIsLoading]);

    const { translate } = useContext(TranslationContext);
    const form = useFormik({
        initialValues: {
            search: ""
        },
        onSubmit: (values) => {
            console.log(values);
        }
    });

    useEffect(() => {
        if (!data) return;
        const value = form.values.search.toLocaleLowerCase();

        if (value) {
            const filtered = data.data.reduce((sections: IFaqSection[], section: IFaqSection) => {
                if (section.title.toLocaleLowerCase().match(value)) {
                    sections = [...sections, section];
                } else {
                    const filteredItems = section.faqItems.filter(
                        (item) =>
                            item.title.toLocaleLowerCase().match(value) ||
                            item.body.toLocaleLowerCase().match(value)
                    );
                    if (filteredItems.length)
                        sections = [...sections, { ...section, faqItems: filteredItems }];
                }

                return sections;
            }, []);

            setFaqs(filtered);
        } else setFaqs(data.data);
        
    }, [form.values, data]);

    return (
        <div className="faqs">
            <h1 className="text-h2-smbd mb-3">
                {translate("common.faqs_long", "Frequently asked questions")}
            </h1>
            <div className="faqs_content d-flex flex-column gap-4">
                <form className="form" onSubmit={form.handleSubmit}>
                    <InputText
                        type="text"
                        name="search"
                        placeholder={translate(
                            "page.faqs.search_placeholder",
                            "Search for a topic"
                        )}
                        value={form.values.search}
                        onChange={form.handleChange}
                        customCSS="search_input"
                        buttonIcons={[{ icon: "magnifier", placement: "start", type: "submit" }]}
                    />
                </form>

                {faqs.map((section, i) => (
                    <section className="faq_subcategory" key={i}>
                        <h2 className="text-bd2-rg text-uppercase mb-1">{section.title}</h2>
                        <Accordion items={section.faqItems} />
                    </section>
                ))}
            </div>
        </div>
    );
}

export default Faqs;
