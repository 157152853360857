import { IGeneric } from "./interfaces/generic.interface";

export const APP_CONFIGS = {
    title: "Prosperh HP",
    key: "hp-app",
    api: {
        base: "ohp"
    },
};

export const CONTENT_BASE_URL = process.env.REACT_APP_BASE_CONTENT_API_URL;

export const COOKIES = {
    TOKEN: "tk",
    TOKEN_EXP_HOURS: 2,
    REFRESH_TOKEN: "rtk",
    REFRESH_TOKEN_EXP_HOURS: 15 * 24,
    STRAPI_TOKEN: "stk",
    STRAPI_TOKEN_EXP_HOURS: 7 * 24,
    LANGUAGE: "lang"
};

export const LOCALE: IGeneric = {
    pt: "pt",
    en: "en",
    sq: "sq",
    hu: "hu-HU",
    es: "es",
    da: "da-DK",
    tr: "tr",
    nl: "nl",
    de: "de"
};

export const LOCALE_LABELS: IGeneric = {
    pt: "Português (PT)",
    en: "English (EN)",
    sq: "Shqip (SQ)",
    hu: "Magyar (HU)",
    es: "Español (SP)",
    da: "Dansk (DEN)",
    tr: "Türkçe (TR)",
    nl: "Dutch (NL)",
    de: "Deutsch (DE)"
};

export const DEFAULT_LANGUAGE = LOCALE.en;
