import { IFirstLoginRequest, ILogin, IPasswordChange, IPasswordRecover } from "../interfaces/authentication.interface";
import { forgotPassword, login, recoverPassword, setFirstLogin, setNewPassword, validateCode, validateRecoveryCode } from "../services/authentication.service";
import { useMutation } from "react-query";


export function useAuthLogin() {
    return useMutation((payload: ILogin) => login(payload));
}

export function useChangePassword(){
    return useMutation((payload: IPasswordChange) => setNewPassword(payload))
}

export function useForgotPassword(){
    return useMutation((payload: string) => forgotPassword(payload))
}

export function useRecoverPassword(){
    return useMutation((payload: IPasswordRecover) => recoverPassword(payload))
}

export function useValidateCode(){
    return useMutation((payload: string) => validateCode(payload))
}

export function useValidateRecoveryCode(){
    return useMutation((payload: string) => validateRecoveryCode(payload))
}

export function useFirstLogin(){
    return useMutation((payload: IFirstLoginRequest) => setFirstLogin(payload))
}