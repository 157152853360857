import { useContext, useEffect, useState } from "react";
import { TranslationContext } from "../../shared/providers/translation/translation.provider";
import { LoadingContext } from "../../shared/providers/loading/loading.provider";
import InputText from "../../components/Forms/InputText/InputText";
import Button from "../../components/Button/Button";
import { Link } from "react-router-dom";
import InputSwitch from "../../components/Forms/InputSwitch/InputSwitch";
import { AuthenticationContext } from "../../shared/providers/authentication/authentication.provider";
import InputCheckbox from "../../components/Forms/InputCheckbox/InputCheckbox";
import { IPasswordChange } from "../../shared/interfaces/authentication.interface";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useChangePassword } from "../../shared/hooks/useAuthentication";
import LanguagePicker from "../../components/LanguagePicker/LanguangePicker";
import { DEFAULT_LANGUAGE } from "../../shared/constants";
import { IUserSettings } from "../../shared/interfaces/user.interface";
import { useSetUserSettings } from "../../shared/hooks/useUser";

function Account() {
    const { translate, lang } = useContext(TranslationContext);
    const { setIsLoading } = useContext(LoadingContext);
    const [isSuccess, setIsSuccess] = useState<boolean | undefined>();
    const [settingPassword, setSettingPassword] = useState<boolean>(false);
    const { user } = useContext(AuthenticationContext);
    const changePassword = useChangePassword();
    const changeSettings = useSetUserSettings();

    const passwordRequirements = [
        {
            label: "Required",
            test: (v: string) => !v,
            display: false
        },
        {
            label: "Between 8 and 64 characters",
            test: (v: string) => v.length < 8 || v.length > 64,
            display: true
        },
        {
            label: "At least one number",
            test: (v: string) => !/[0-9]/.test(v),
            display: true
        },
        {
            label: "At least one uppercase letter",
            test: (v: string) => !/[A-Z]/.test(v),
            display: true
        },
        {
            label: "At least one special character (!, @, #, $, %, ^, &, *)",
            test: (v: string) => !/[!@#$%^&*]/.test(v),
            display: true
        },
        {
            label: "At least one lowercase letter",
            test: (v: string) => !/[a-z]/.test(v),
            display: true
        }
    ];

    const passwordForm = useFormik({
        initialValues: {
            currentPassword: "",
            newPassword: "",
            confirmNewPassword: ""
        },
        validationSchema: Yup.object().shape({
            newPassword: Yup.string().test("password-rules", "Invalid password", function (val) {
                const errors: string[] = [];
                const value = val ?? "";

                passwordRequirements.forEach((r) => {
                    if (r.test(value)) errors.push(r.label);
                });

                if (errors.length) {
                    return this.createError({
                        message: errors.join("/")
                    });
                }

                return true;
            }),
            confirmNewPassword: Yup.string()
                .required("Confirm password please")
                .test("confirm-password", "Passwords do not match", function (val) {
                    return this.parent.newPassword === val;
                })
        }),
        validateOnChange: true,
        onSubmit: async (values: IPasswordChange) => {
            setIsLoading(true);
            try {
                console.log(values);
                await changePassword.mutateAsync({
                    currentPassword: values.currentPassword,
                    newPassword: values.newPassword
                });
                setIsSuccess(true);
            } catch (e) {
                setIsSuccess(false);
            }
            setIsLoading(false);
            setSettingPassword(false);
            passwordForm.resetForm();
        }
    });

    const communicationSettings = useFormik({
        initialValues: {
            notifyPush: true,
            notifyEmail: false,
            communicationLanguage: lang ?? DEFAULT_LANGUAGE 
        },
        validateOnChange: true,
        onSubmit: async (values: IUserSettings) => {
            setIsLoading(true);
            try {
                console.log(values);
                await changeSettings.mutateAsync({ ...values, communicationLanguage: lang ?? DEFAULT_LANGUAGE });
                setIsSuccess(true);
            } catch (e) {
                setIsSuccess(false);
            }
            setIsLoading(false);
        }
    });
    
    useEffect(() => {
        console.log(passwordForm.values)
        passwordForm.setFieldValue("currentPassword", "current password");
    }, [passwordForm.initialValues]);

    const passwordLevels = ["", "Very Weak", "Weak", "Medium", "Good", "Very Good"];

    const getCurrentPasswordState = (): number => {
        return passwordForm.dirty
            ? Number(
                  passwordLevels.length -
                      1 -
                      (passwordForm.errors.newPassword?.split("/").length ?? 0)
              )
            : 0;
    };

    const checkRequirement = (label: string) => {
        return passwordForm.errors.newPassword?.split("/").some((lb) => lb === label);
    };

    return (
        <div className="account">
            <h1 className="text-h2-smbd mb-3">{translate("common.my_account", "My account")}</h1>
            <div className="d-flex flex-column gap-4">
                <section aria-label={translate("account.personal_data", "Personal data")}>
                    <div className="d-flex justify-content-between align-items-center mb-22 flex-wrap gap-12">
                        <h2 className="text-h3-md" id="password_settings">
                            {translate("account.personal_data", "Personal data")}
                        </h2>
                        <Link to={""} className="color-green-500 d-flex align-items-center gap-03">
                            <i className="icon icon-24 icon-help"></i>
                            <span className="text-bd2-bd">
                                {translate(
                                    "account.change_info_request",
                                    "Need to change personal data information? Contact us"
                                )}
                            </span>
                        </Link>
                    </div>
                    <form className="form row gx-5 gy-22">
                        <div className="col-12 col-lg-6">
                            <InputText
                                disabled
                                type="text"
                                label={translate("account.first_name", "First name")}
                                name="firstName"
                                placeholder={translate(
                                    "account.placeholder.first_name",
                                    "Insert first name"
                                )}
                                value={user?.name}
                            />
                        </div>
                        <div className="col-12 col-lg-6 order-2">
                            <InputText
                                disabled
                                type="text"
                                label={translate("account.last_name", "Last name")}
                                name="lastName"
                                placeholder={translate(
                                    "account.placeholder.last_name",
                                    "Insert last name"
                                )}
                                value={user?.name}
                            />
                        </div>
                        <div className="col-12 col-lg-6 order-1">
                            <InputText
                                disabled
                                type="text"
                                label={translate("common.email", "Email")}
                                name="email"
                                placeholder={translate(
                                    "passwordForm.placeholder.email",
                                    "Insert email"
                                )}
                                value={user?.email}
                                onChange={() => {}}
                            />
                        </div>
                        <div className="col-12 col-lg-6 order-3">
                            <InputText
                                disabled
                                type="text"
                                label={translate("account.job_title", "Job title")}
                                name="jobTitle"
                                placeholder={translate(
                                    "account.placeholder.job_title",
                                    "Insert job title"
                                )}
                                onChange={() => {}}
                            />
                        </div>
                    </form>
                </section>
                <hr className="is_sm" />
                <form
                    className="form"
                    aria-labelledby="password_settings"
                    onSubmit={passwordForm.handleSubmit}>
                    <div className="d-flex justify-content-between align-items-center mb-22 flex-wrap gap-12">
                        <h2 className="text-h3-md" id="password_settings">
                            {translate("account.password_settings", "Password settings")}
                        </h2>
                        {!settingPassword && (
                            <Button
                                icon="edit"
                                type="button"
                                variant="outlined"
                                onClick={() => {setSettingPassword(true); passwordForm.resetForm()}}
                                label={translate("page.login.change_password", "Change password")}
                            />
                        )}
                        {settingPassword && (
                            <Button
                                icon="edit"
                                type="submit"
                                variant="outlined"
                                label={translate(
                                    "account.save_password_settings",
                                    "Save password settings"
                                )}
                            />
                        )}
                    </div>
                    <div className="row gx-5 gy-22">
                        <div className="col-12">
                            <InputText
                                name="currentPassword"
                                label={translate("common.password", "Password")}
                                placeholder={translate(
                                    "passwordForm.placeholder.password",
                                    "Insert password"
                                )}
                                type="password"
                                onChange={passwordForm.handleChange}
                                value={passwordForm.values.currentPassword}
                                disabled={!settingPassword}
                            />
                        </div>
                        {settingPassword && (
                            <>
                                <div className="col-12 col-lg-6">
                                    <InputText
                                        name="newPassword"
                                        label={translate("common.new_password", "New password")}
                                        placeholder={translate(
                                            "passwordForm.placeholder.new_password",
                                            "Insert new password"
                                        )}
                                        type="password"
                                        customCSS={
                                            passwordForm.errors.newPassword ? "has_error" : ""
                                        }
                                        currentStep={getCurrentPasswordState()}
                                        totalSteps={
                                            passwordForm.errors.newPassword
                                                ? passwordLevels.length - 1
                                                : undefined
                                        }
                                        stepLabel={passwordLevels[getCurrentPasswordState()]}
                                        onChange={passwordForm.handleChange}
                                        value={passwordForm.values.newPassword}
                                    />
                                    {passwordForm.errors.newPassword && (
                                        <div className="form_field mt-1">
                                            <ul className="form_field-requirements">
                                                {passwordRequirements.map(
                                                    (req, i) =>
                                                        req.display && (
                                                            <li
                                                                className="text-bd3-md d-flex gap-02 align-items-start"
                                                                key={i}>
                                                                <i
                                                                    className={`icon icon-16 ${
                                                                        checkRequirement(req.label)
                                                                            ? "icon-misuse color-red-600"
                                                                            : "icon-checkmark--filled color-green-600"
                                                                    }`}></i>
                                                                {req.label}
                                                            </li>
                                                        )
                                                )}
                                            </ul>
                                        </div>
                                    )}
                                </div>
                                <div className="col-12 col-lg-6">
                                    <InputText
                                        type="password"
                                        label={translate(
                                            "passwordForm.confirm_password",
                                            "Confirm password"
                                        )}
                                        name="confirmNewPassword"
                                        placeholder={translate(
                                            "passwordForm.placeholder.confirm_password",
                                            "Confirm password"
                                        )}
                                        errors={
                                            passwordForm.submitCount > 0
                                                ? passwordForm.errors.confirmNewPassword?.split("/")
                                                : []
                                        }
                                        onChange={passwordForm.handleChange}
                                        value={passwordForm.values.confirmNewPassword}
                                    />
                                </div>
                            </>
                        )}
                    </div>
                </form>
                <hr className="is_sm" />
                <form onSubmit={communicationSettings.handleSubmit}>
                    <div className="d-flex justify-content-between align-items-center mb-22 flex-wrap gap-12">
                        <h2 className="text-h3-md mb-22">
                            {translate("account.communication_settings", "Communication settings")}
                        </h2>
                        <Button
                            icon="edit"
                            type="submit"
                            variant="outlined"
                            label={translate("page.account.save_settings", "Save settings")}
                        />
                    </div>
                    <div className="row gx-5 gy-3">
                        <div className="col-12 col-lg-4 d-flex gap-22 flex-column">
                            <InputSwitch
                                label="Push notifications"
                                name="notifyPush"
                                checked={communicationSettings.values.notifyPush}
                                onChange={(v) => {
                                    communicationSettings.setFieldValue("notifyPush", v);
                                }}
                            />
                            <InputCheckbox
                                label="Email communications"
                                name="notifyEmail"
                                checked={communicationSettings.values.notifyEmail}
                                onChange={(v) => {
                                    communicationSettings.setFieldValue("notifyEmail", v);
                                }}
                            />
                            <LanguagePicker />
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default Account;
