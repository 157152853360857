import { CSSProperties, useCallback, useEffect, useRef, useState } from "react";
import { IAccordionItemProps, IAccordionProps } from "./Accordion.interface";
import { markdownToHtml } from "../../shared/helpers/utils";

function Accordion({ items }: IAccordionProps) {
    const [activeIndex, setActiveIndex] = useState<number>();
    const [isTransitioning, setIsTransitioning] = useState<boolean>();

    useEffect(() => {
        setIsTransitioning(true);

        const timeout = setTimeout(() => {
            setIsTransitioning(false);
        }, 1000);

        return () => clearTimeout(timeout);
    }, [activeIndex]);

    return (
        <div className="accordion">
            {items.map((item, i) => (
                <AccordionItem
                    key={i}
                    index={i}
                    activeIndex={activeIndex}
                    isTransitioning={isTransitioning}
                    setActiveIndex={setActiveIndex}
                    title={item.title}
                    body={item.body}
                />
            ))}
        </div>
    );
}

function AccordionItem({
    index: i,
    activeIndex,
    setActiveIndex,
    isTransitioning,
    title,
    body
}: IAccordionItemProps) {
    const bodyRef = useRef<HTMLDivElement>(null);
    const getItemCss = useCallback(
        (i: number) => {
            if (isTransitioning && i === activeIndex) return "is_transitioning";
            if (i === activeIndex) return "is_active";

            return "";
        },
        [activeIndex, isTransitioning]
    );

    return (
        <div
            className={`accordion_item ${getItemCss(i)}`}
            style={{ "--body-height": `${bodyRef.current?.clientHeight}px` } as CSSProperties}>
            <button
                type="button"
                className="accordion_btn"
                onClick={() => (i !== activeIndex ? setActiveIndex(i) : setActiveIndex(undefined))}>
                {title}
            </button>
            <div className="accordion_body-wrapper">
                <div
                    className="accordion_body"
                    ref={bodyRef}
                    dangerouslySetInnerHTML={{ __html: markdownToHtml(body) }}
                />
            </div>
        </div>
    );
}

export default Accordion;
