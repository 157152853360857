import { useEffect, useRef } from "react";
import { IModalProps } from "./Modal.interface";
import Button from "../Button/Button";

function Modal({ isOpen, setIsOpen, title, children }: IModalProps) {
    const modal = useRef<HTMLDialogElement>(null);

    useEffect(() => {
        if (isOpen) {
            modal.current?.showModal();
            document.documentElement.classList.add("overflow-hidden");
        } else {
            modal.current?.close();
            document.documentElement.classList.remove("overflow-hidden");
        }
    }, [isOpen]);

    useEffect(() => {
        const observer = new MutationObserver(() => setIsOpen(modal.current?.open ?? false));
        observer.observe(modal.current as Node, { attributes: true });
    }, [modal, setIsOpen]);

    return (
        <dialog ref={modal} className="modal">
            <div className="modal_header d-flex align-items-center justify-content-between">
                <span className="text-bd1-smbd">{title}</span>
                <Button type="button" customCss="pe-0" icon="misuse" onClick={() => setIsOpen((v) => !v)} />
            </div>
            <div className="modal_body">{children}</div>
        </dialog>
    );
}

export default Modal;
