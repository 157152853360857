import { useContext, useEffect, useState } from "react";
import { markdownToHtml } from "../../../shared/helpers/utils";
import Button from "../../Button/Button";
import Modal from "../../Modal/Modal";
import { IRichTextProps } from "./Richtext.interface";
import { ModuleContext } from "../../../shared/providers/module/module.provider";
import { TranslationContext } from "../../../shared/providers/translation/translation.provider";
import { ItemTypes } from "../../../shared/interfaces/modules.interface";

function RichText(props: IRichTextProps) {
    if (props.isApp) return <RichTextApp {...props} />;

    return (
        <div
            className="text-bd2-md rich_text"
            dangerouslySetInnerHTML={{ __html: markdownToHtml(props.content) }}
        />
    );
}

export default RichText;

function RichTextApp(props: IRichTextProps) {
    const { setProgress } = useContext(ModuleContext);
    const { lang } = useContext(TranslationContext);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [activeSection, setActiveSection] = useState<number>(0);
    const [paragraphs, setParagraphs] = useState<string[]>([]);

    useEffect(() => {
        let grouping = props.content.split("\n").filter((p) => p);

        setParagraphs(grouping);
    }, [props]);

    useEffect(() => {}, [activeSection, paragraphs]);

    const handleProgress = () => {
        if (lang && props.unitId) {
            setProgress({
                unitId: props.unitId,
                itemId: props.id,
                progress: 1,
                itemType: ItemTypes.RICH_TEXT,
                itemLocale: lang
            });
        }
    };

    return (
        <>
            <Button
                type="button"
                label="RT"
                customCss="card card_component-app"
                onClick={() => {
                    setIsOpen((v) => !v);
                    handleProgress();
                }}
            />
            <Modal isOpen={isOpen} setIsOpen={setIsOpen} title={props.title}>
                {paragraphs.map((p, i) => {
                    return (
                        <Slide key={i} isActive={i === activeSection} content={markdownToHtml(p)} />
                    );
                })}
                <div className="d-flex gap-1">
                    <Button
                        type="button"
                        label="<"
                        onClick={() => setActiveSection((s) => (s <= 0 ? s : (s -= 1)))}
                    />
                    <Button
                        type="button"
                        label=">"
                        onClick={() =>
                            setActiveSection((s) => (s >= paragraphs.length - 1 ? s : (s += 1)))
                        }
                    />
                </div>
            </Modal>
        </>
    );
}

function Slide(props: any) {
    return (
        <div
            className={`text-h1-md ${props.isActive ? "d-block" : "d-none"}`}
            dangerouslySetInnerHTML={{ __html: props.content }}
        />
    );
}
