import { useContext, useEffect } from "react";
import { AuthenticationContext } from "../providers/authentication/authentication.provider";
import { IRouteGuardProps } from "./route.interface";
import { Navigate, useLocation } from "react-router-dom";
import { routes } from "../router/routes";
import Loading from "../../components/Loading/Loading";
import { getCookies } from "../helpers/utils";
import { COOKIES } from "../constants";

function RouteGuard({ children }: IRouteGuardProps) {
    const { isAuthenticated, setIsAuthenticated } = useContext(AuthenticationContext);
    const location = useLocation();

    useEffect(() => {
        const cookies = getCookies();

        if (cookies[COOKIES.REFRESH_TOKEN]) setIsAuthenticated(true);
        else setIsAuthenticated(false);
    }, [location, setIsAuthenticated]);

    if (isAuthenticated === undefined) return <Loading />;

    if (!isAuthenticated) return <Navigate to={routes.LOGIN.path} />;

    return <>{children}</>;
}

export default RouteGuard;
