import { createContext, useCallback, useEffect, useState } from 'react';
import { IAuthContext, IAuthProps } from './authentication.interface';
import { clearCookies, getCookies, setCookie } from '../../helpers/utils';
import { IAuthTokens } from '../../interfaces/authentication.interface';
import { IUser } from '../../interfaces/user.interface';
import { COOKIES } from '../../constants';
import { useGetUser } from '../../hooks/useUser';

const authenticationInitValues = {
    isAuthenticated: undefined,
    setIsAuthenticated: () => {},
    authTokens: undefined,
    setAuthTokens: () => {},
    user: undefined,
    setUser: () => {}
};

export const AuthenticationContext = createContext<IAuthContext>(authenticationInitValues);

const AuthenticationProvider = ({ children }: IAuthProps) => {
    const [user, setUser] = useState<IUser>();
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>();
    const [authTokens, setAuthTokens] = useState<IAuthTokens>();
    const getUserDetails = useGetUser();

    const getUser = useCallback(async (): Promise<void> => {
        const response = await getUserDetails.mutateAsync();
        
        if (response.success) {
            setUser(response.data as IUser);
            setIsAuthenticated(true);
        } else {
            setIsAuthenticated(false);
            clearCookies();
        }
    }, [getUserDetails]);

    useEffect(() => {
        const cookies = getCookies();

        if (cookies[COOKIES.REFRESH_TOKEN]) {
            setAuthTokens({
                access_token: cookies[COOKIES.TOKEN] as string,
                refresh_token: cookies[COOKIES.REFRESH_TOKEN] as string
            });
        } else {
            setIsAuthenticated(false);
        }
    }, []);

    useEffect(() => {
        const cookies = getCookies();
        if (authTokens) {
            getUser();
            setCookie(
                COOKIES.TOKEN,
                authTokens?.access_token,
                COOKIES.TOKEN_EXP_HOURS,
                '/',
                null,
                true
            );
            setCookie(
                COOKIES.REFRESH_TOKEN,
                authTokens?.refresh_token,
                COOKIES.REFRESH_TOKEN_EXP_HOURS,
                '/',
                null,
                true
            );
        } else if (!cookies[COOKIES.TOKEN] && !cookies[COOKIES.REFRESH_TOKEN]) {
            setIsAuthenticated(false);
        }
    }, [authTokens]);

    return (
        <AuthenticationContext.Provider
            value={{
                isAuthenticated,
                setIsAuthenticated,
                authTokens,
                setAuthTokens,
                user,
                setUser
            }}>
            {children}
        </AuthenticationContext.Provider>
    );
};

export default AuthenticationProvider;
