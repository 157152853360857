import { IRequest } from "../interfaces/authentication.interface";
import { ITranslationResponse, ITranslations } from "../interfaces/translations.interface";
import { strapiRequest } from "./requests.service";
import { DEFAULT_LANGUAGE, LOCALE } from "../constants";

export const getTranslations = async (): Promise<IRequest<ITranslations>> => {
    try {
        const query = `
            query translations($pagination: PaginationArg, $locale: I18NLocaleCode) {
                translations(pagination: $pagination, locale: $locale) {
                    documentId
                    key
                    value
                    locale
                }
            }
                        `;

        const variables = {
            pagination: {
                pageSize: 20000000
            },
            locale: "*"
        };

        const response = await strapiRequest.post<ITranslationResponse>(`/graphql`, {
            operationName: "translations",
            query,
            variables
        });

        const parsedTranslations = parseTranslations(response.data);
        return { success: true, data: parsedTranslations };
    } catch (error: any) {
        return { success: false, data: error as any };
    }
};

const parseTranslations = (translations: ITranslationResponse): ITranslations => {
    const translationsMap = {};

    const assignLocale = (key: string, locale: string, value: string) => {
        if (Object.keys(LOCALE).findIndex((k) => LOCALE[k] === locale) < 0) return;
        Object.assign(translationsMap, {
            [`${key}-${locale}`]: value
        });
    };

    translations.data.translations.forEach((t) => {
        Object.assign(translationsMap, { [`${t.key}-id`]: t.documentId });

        assignLocale(t.key, t.locale, t.value);
    });

    return translationsMap as ITranslations;
};

export const setTranslation = async (
    key: string,
    translations: {
        id: string;
        [key: string]: string;
    }
): Promise<void> => {
    if (translations.id) {
        let id = await updateTranslation({
            id: translations.id,
            key,
            value: translations[DEFAULT_LANGUAGE],
            locale: DEFAULT_LANGUAGE
        });

        if (!id) {
            id = await postTranslation({
                key,
                value: translations[DEFAULT_LANGUAGE],
                locale: DEFAULT_LANGUAGE
            });
        }

        Object.keys(LOCALE).forEach((locale) => {
            updateTranslation({
                id: id as string,
                key,
                value: translations[locale],
                locale: LOCALE[locale]
            });
        });
    } else {
        let id = await postTranslation({
            key,
            value: translations[DEFAULT_LANGUAGE],
            locale: DEFAULT_LANGUAGE
        });

        if (!id) return;

        Object.keys(LOCALE).forEach((locale) => {
            if (locale === DEFAULT_LANGUAGE) return;
            updateTranslation({
                id: id as string,
                key,
                value: translations[locale],
                locale: LOCALE[locale]
            });
        });
    }
};

export const postTranslation = async (values: {
    key: string;
    value: string;
    locale: any;
}): Promise<string | null> => {
    try {
        const response = await strapiRequest.post<any>(
            `/api/translations?locale=${values.locale}`,
            {
                data: {
                    ...values
                }
            }
        );
        console.log(response);

        return response.data.data.documentId;
    } catch (e) {
        return null;
    }
};

export const updateTranslation = async (values: {
    id: string;
    key: string;
    value: string;
    locale: string;
}): Promise<string | null> => {
    if (values.value === "") return null;
    try {
        const response = await strapiRequest.put<any>(
            `/api/translations/${values.id}?locale=${values.locale}`,
            {
                data: {
                    key: values.key,
                    value: values.value,
                    locale: values.locale
                }
            }
        );
        return response.data.data.documentId;
    } catch (e) {
        return null;
    }
};
