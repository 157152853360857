import { ComponentTypes } from "../../../shared/interfaces/modules.interface";
import Audio from "../AudioComponent/Audio";
import { IAudioProps } from "../AudioComponent/Audio.interface";
import Form from "../FormComponent/Form";
import { IFormProps } from "../FormComponent/Form.interface";
import Image from "../ImageComponent/Image";
import { IImageProps } from "../ImageComponent/Image.interface";
import RichText from "../RichTextComponent/RichText";
import Video from "../VideoComponent/Video";
import { ComponentPickerProps } from "./ComponentPicker.interface";

function ComponentPicker({ ...props }: ComponentPickerProps) {
    const { type } = props;
    if (type === ComponentTypes.IMAGE && props.image) {
        return <Image {...props as IImageProps} />;
    } else if (type === ComponentTypes.RICH_TEXT) {
        return <RichText {...props} title={props.title} content={props.content} />;
    } else if (type === ComponentTypes.VIDEO) {
        return <Video {...props} title={props.title} url={props.url} />;
    } else if (type === ComponentTypes.AUDIO) {
        return <Audio {...props as IAudioProps} />;
    } else if (type === ComponentTypes.FORM) {
        return <Form {...(props as IFormProps)} />;
    }

    return <>{type}</>;
}

export default ComponentPicker;
