import { IInfoCardProps } from "./InfoCard.interface";

function InfoCard({ icon = "help-desk", title, text }: IInfoCardProps) {
    return (
        <div className="info_card">
            <span className="info_card-icon">
                <i className={`icon icon-32 icon-${icon}`}></i>
            </span>
            <h2 className="info_card-title text-h2-smbd">{title}</h2>
            <p className="text-bd2-rg color-neutrals-dark">{text}</p>
        </div>
    );
}

export default InfoCard;
