import { useEffect, useState } from "react";
import { IFavoriteButtonProps } from "./FavoriteButton.interface";

function FavoriteButton(props: IFavoriteButtonProps) {
    const [isActive, setIsActive] = useState(props.isFavorite);

    useEffect(() => {
        setIsActive(props.isFavorite);
    }, [props.isFavorite]);

    const handleClick = () => {
        setIsActive((v) => !v);
        if (props.onClick) props.onClick();
    };

    return (
        <button
            type="button"
            className={`${props.className} ${isActive ? "color-red-600" : "color-neutrals-neutral"}`}
            onClick={handleClick}>
            <i
                className={`icon icon-24 ${isActive ? "icon-favorite--filled" : "icon-favorite"}`}></i>
        </button>
    );
}

export default FavoriteButton;
