import { CSSProperties } from "react";
import { IButtonProps } from "./Button.interface";

function Button({
    type,
    label,
    onClick,
    variant,
    state = "normal",
    icon,
    isIconRight,
    customCss = ""
}: IButtonProps) {
    const getVariant = () => {
        switch (`${variant}-${state}`) {
            case "solid-normal":
                return {
                    css: "is_solid text-bd2-bd",
                    mainColor: "green-500",
                    textColor: "neutrals-darker"
                };
            case "outlined-normal":
                return {
                    css: "is_outlined text-bd2-bd",
                    mainColor: "green-800",
                    textColor: "neutrals-darker"
                };
            case "link-normal":
                return {
                    css: "is_link text-bd2-bd",
                    mainColor: "green-500",
                    textColor: "CurrentColor"
                };
            case "solid-disabled":
                return {
                    css: "is_solid is_disabled text-bd2-bd",
                    mainColor: "neutrals-lighter",
                    textColor: "neutrals-dark"
                };
            case "outlined-disabled":
                return {
                    css: "is_outlined is_disabled text-bd2-bd",
                    mainColor: "neutrals-lighter",
                    textColor: "neutrals-dark"
                };
            case "link-disabled":
                return {
                    css: "is_link is_disabled text-bd2-bd",
                    mainColor: "",
                    textColor: "CurrentColor"
                };

            default:
                return {
                    css: "",
                    mainColor: "",
                    textColor: ""
                };
        }
    };

    return (
        <button
            type={type}
            style={
                {
                    "--main-color": `var(--${getVariant().mainColor})`,
                    "--text-color": `var(--${getVariant().textColor})`
                } as CSSProperties
            }
            className={`button ${customCss} ${getVariant().css} ${isIconRight ? "flex-row-reverse" : ""}`}
            onClick={onClick}>
            {icon && <i className={`icon icon-24 icon-${icon}`}></i>}
            {label && <span>{label}</span>}
        </button>
    );
}

export default Button;
