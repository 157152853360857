import { useNavigate } from "react-router-dom";
import { logout } from "../../shared/services/authentication.service";
import { routes } from "../../shared/router/routes";
import Button from "../../components/Button/Button";
import Link from "../../components/Link/Link";
import { useContext, useEffect } from "react";
import { AuthenticationContext } from "../../shared/providers/authentication/authentication.provider";
import { useTranslation } from "../../shared/hooks/useTranslation";

function Styleguide() {
    const navigate = useNavigate();
    const { setIsAuthenticated } = useContext(AuthenticationContext);
    const { data } = useTranslation();
    const colors = [
        "blue-50",
        "blue-100",
        "blue-200",
        "blue-300",
        "blue-400",
        "blue-500",
        "blue-600",
        "blue-700",
        "blue-800",
        "blue-900",
        "yellow-50",
        "yellow-100",
        "yellow-200",
        "yellow-300",
        "yellow-400",
        "yellow-500",
        "yellow-600",
        "yellow-700",
        "yellow-800",
        "yellow-900",
        "red-50",
        "red-100",
        "red-200",
        "red-300",
        "red-400",
        "red-500",
        "red-600",
        "red-700",
        "red-800",
        "red-900",
        "green-50",
        "green-100",
        "green-200",
        "green-300",
        "green-400",
        "green-500",
        "green-600",
        "green-700",
        "green-800",
        "green-900",
        "neutrals-white",
        "neutrals-lighter",
        "neutrals-light",
        "neutrals-neutral",
        "neutrals-dark",
        "neutrals-darker",
        "neutrals-black",
        "bg-light-grey",
        "bg-neutral-grey"
    ];

    const onLogout = () => {
        logout().then((_) => {
            setIsAuthenticated(false);
            navigate(routes.LOGIN.path);
        });
    };

    useEffect(() => {
        console.log({ translations: data });
    });

    return (
        <div className="home">
            <div className="d-flex flex-column gap-5">
                <div className="row">
                    <div className="col-12 col-md-3">
                        <h1 className="text-h1-bd mb-4">Typography</h1>
                    </div>
                    <div className="col-12 col-md-9">
                        <div className="row g-01">
                            <div className="col-6 col-md-3 col-lg-1">
                                <h1 className="text-h1-thin text-h1-thin-md">H1</h1>
                                <h1 className="text-h1-xlight text-h1-xlight-md">H1</h1>
                                <h1 className="text-h1-light text-h1-light-md">H1</h1>
                                <h1 className="text-h1-rg text-h1-rg-md">H1</h1>
                                <h1 className="text-h1-md text-h1-md-md">H1</h1>
                                <h1 className="text-h1-smbd text-h1-smbd-md">H1</h1>
                                <h1 className="text-h1-bd text-h1-bd-md">H1</h1>
                                <h1 className="text-h1-xbd text-h1-xbd-md">H1</h1>
                                <h1 className="text-h1-black text-h1-black-md">H1</h1>
                            </div>
                            <div className="col-6 col-md-3 col-lg-1">
                                <h2 className="text-h2-thin text-h2-thin-md">h2</h2>
                                <h2 className="text-h2-xlight text-h2-xlight-md">h2</h2>
                                <h2 className="text-h2-light text-h2-light-md">h2</h2>
                                <h2 className="text-h2-rg text-h2-rg-md">h2</h2>
                                <h2 className="text-h2-md text-h2-md-md">h2</h2>
                                <h2 className="text-h2-smbd text-h2-smbd-md">h2</h2>
                                <h2 className="text-h2-bd text-h2-bd-md">h2</h2>
                                <h2 className="text-h2-xbd text-h2-xbd-md">h2</h2>
                                <h2 className="text-h2-black text-h2-black-md">h2</h2>
                            </div>
                            <div className="col-6 col-md-3 col-lg-1">
                                <h3 className="text-h3-thin text-h3-thin-md">h3</h3>
                                <h3 className="text-h3-xlight text-h3-xlight-md">h3</h3>
                                <h3 className="text-h3-light text-h3-light-md">h3</h3>
                                <h3 className="text-h3-rg text-h3-rg-md">h3</h3>
                                <h3 className="text-h3-md text-h3-md-md">h3</h3>
                                <h3 className="text-h3-smbd text-h3-smbd-md">h3</h3>
                                <h3 className="text-h3-bd text-h3-bd-md">h3</h3>
                                <h3 className="text-h3-xbd text-h3-xbd-md">h3</h3>
                                <h3 className="text-h3-black text-h3-black-md">h3</h3>
                            </div>
                            <div className="col-6 col-md-3 col-lg-1">
                                <p className="text-bd1-thin text-bd1-thin-md">body 1</p>
                                <p className="text-bd1-xlight text-bd1-xlight-md">body 1</p>
                                <p className="text-bd1-light text-bd1-light-md">body 1</p>
                                <p className="text-bd1-rg text-bd1-rg-md">body 1</p>
                                <p className="text-bd1-md text-bd1-md-md">body 1</p>
                                <p className="text-bd1-smbd text-bd1-smbd-md">body 1</p>
                                <p className="text-bd1-bd text-bd1-bd-md">body 1</p>
                                <p className="text-bd1-xbd text-bd1-xbd-md">body 1</p>
                                <p className="text-bd1-black text-bd1-black-md">body 1</p>
                            </div>
                            <div className="col-6 col-md-3 col-lg-1">
                                <p className="text-bd2-thin text-bd2-thin-md">body 2</p>
                                <p className="text-bd2-xlight text-bd2-xlight-md">body 2</p>
                                <p className="text-bd2-light text-bd2-light-md">body 2</p>
                                <p className="text-bd2-rg text-bd2-rg-md">body 2</p>
                                <p className="text-bd2-md text-bd2-md-md">body 2</p>
                                <p className="text-bd2-smbd text-bd2-smbd-md">body 2</p>
                                <p className="text-bd2-bd text-bd2-bd-md">body 2</p>
                                <p className="text-bd2-xbd text-bd2-xbd-md">body 2</p>
                                <p className="text-bd2-black text-bd2-black-md">body 2</p>
                            </div>
                            <div className="col-6 col-md-3 col-lg-1">
                                <p className="text-bd3-thin text-bd3-thin-md">body 3</p>
                                <p className="text-bd3-xlight text-bd3-xlight-md">body 3</p>
                                <p className="text-bd3-light text-bd3-light-md">body 3</p>
                                <p className="text-bd3-rg text-bd3-rg-md">body 3</p>
                                <p className="text-bd3-md text-bd3-md-md">body 3</p>
                                <p className="text-bd3-smbd text-bd3-smbd-md">body 3</p>
                                <p className="text-bd3-bd text-bd3-bd-md">body 3</p>
                                <p className="text-bd3-xbd text-bd3-xbd-md">body 3</p>
                                <p className="text-bd3-black text-bd3-black-md">body 3</p>
                            </div>
                            <div className="col-6 col-md-3 col-lg-1">
                                <p className="text-bd4-thin text-bd4-thin-md">body 4</p>
                                <p className="text-bd4-xlight text-bd4-xlight-md">body 4</p>
                                <p className="text-bd4-light text-bd4-light-md">body 4</p>
                                <p className="text-bd4-rg text-bd4-rg-md">body 4</p>
                                <p className="text-bd4-md text-bd4-md-md">body 4</p>
                                <p className="text-bd4-smbd text-bd4-smbd-md">body 4</p>
                                <p className="text-bd4-bd text-bd4-bd-md">body 4</p>
                                <p className="text-bd4-xbd text-bd4-xbd-md">body 4</p>
                                <p className="text-bd4-black text-bd4-black-md">body 4</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-md-3">
                        <h1 className="text-h1-bd mb-4">Colors</h1>
                    </div>
                    <div className="col-12 col-md-9">
                        <div className="row g-01 g-md-1">
                            {colors.map((color) => (
                                <div className="col-1" key={color}>
                                    <div
                                        className={`p-01 text-bd4-smbd bg-${color} color-${color}`}
                                        style={{ aspectRatio: 1, width: "100%" }}>
                                        <span
                                            className="d-none d-lg-block"
                                            style={{
                                                filter: "saturate(10) grayscale(1) invert(1) contrast(10)",
                                                color: "currentcolor"
                                            }}>
                                            {color}
                                        </span>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-md-3">
                        <h1 className="text-h1-bd mb-4">Buttons</h1>
                    </div>
                    <div className="col-12 col-md-9">
                        <div className="d-flex flex-wrap gap-2 align-items-center">
                            <Button type="button" label="Logout" onClick={onLogout} />
                            <Button
                                type="button"
                                variant="outlined"
                                label="Logout"
                                onClick={onLogout}
                            />
                            <Button
                                type="button"
                                variant="link"
                                label="Logout"
                                onClick={onLogout}
                            />
                            <hr className="w-100" />
                            <Button
                                type="button"
                                icon="chevron--down"
                                label="Logout"
                                variant="solid"
                                onClick={onLogout}
                            />
                            <Button
                                type="button"
                                icon="chevron--down"
                                variant="outlined"
                                label="Logout"
                                onClick={onLogout}
                            />
                            <Button
                                type="button"
                                icon="chevron--down"
                                variant="link"
                                label="Logout"
                                onClick={onLogout}
                            />
                            <hr className="w-100" />
                            <Button
                                type="button"
                                icon="edit"
                                isIconRight
                                variant="solid"
                                label="Logout"
                                onClick={onLogout}
                            />
                            <Button
                                type="button"
                                icon="edit"
                                isIconRight
                                variant="outlined"
                                label="Logout"
                                onClick={onLogout}
                            />
                            <Button
                                type="button"
                                icon="edit"
                                isIconRight
                                variant="link"
                                label="Logout"
                                onClick={onLogout}
                            />
                            <hr className="w-100" />
                            <Button
                                type="button"
                                state={"disabled"}
                                icon="edit"
                                isIconRight
                                variant="solid"
                                label="Logout"
                                onClick={onLogout}
                            />
                            <Button
                                type="button"
                                state={"disabled"}
                                icon="edit"
                                isIconRight
                                variant="outlined"
                                label="Logout"
                                onClick={onLogout}
                            />
                            <Button
                                type="button"
                                state={"disabled"}
                                icon="edit"
                                isIconRight
                                variant="link"
                                label="Logout"
                                onClick={onLogout}
                            />
                            <hr className="w-100" />
                            <Link to={routes.HOME.path} icon="edit" label="Logout" />
                            <Link
                                to={routes.HOME.path}
                                icon="edit"
                                isIconRight
                                variant="outlined"
                                label="Logout"
                            />
                            <Link
                                to={routes.HOME.path}
                                state={"disabled"}
                                icon="edit"
                                isIconRight
                                variant="link"
                                label="Logout"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Styleguide;
