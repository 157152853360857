import { useEffect, useRef, useState } from "react";
import { IDropdownPlacement, IDropdownProps } from "./Dropdown.interface";
import { delay } from "../../shared/helpers/utils";
import { useLocation } from "react-router-dom";

function Dropdown({ buttonContent, dropdownContent }: IDropdownProps) {
    const DEFAULT_PLACEMENT = "bottom right";
    const DEFAULT_OFFSET = 300;
    const location = useLocation();

    const dropdownRef = useRef<HTMLDivElement>(null);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [placement, setPlacement] = useState<IDropdownPlacement | undefined>();
    const dropdownContentRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        setIsOpen(false);
    }, [location]);

    useEffect(() => {
        if (!dropdownContentRef.current || !isOpen) return;

        const coords = dropdownContentRef.current?.getBoundingClientRect();
        let place = "";

        if (coords.top < 0) place += "bottom";
        else if (coords.top > 0 && coords.bottom + DEFAULT_OFFSET >= window.innerHeight)
            place += "top";

        if (coords.left >= 0 && coords.right + DEFAULT_OFFSET >= window.innerWidth)
            place += " right";
        else place += " left";

        if (place === "") place = DEFAULT_PLACEMENT;

        setPlacement(place as IDropdownPlacement);
    }, [isOpen]);

    useEffect(() => {
        function clickOutside(e: Event) {
            const clickedEl = e.target as HTMLElement;
            if (dropdownRef.current?.contains(clickedEl)) return;
            setIsOpen(false);
        }

        async function onBlur() {
            await delay(100);
            if (dropdownRef.current?.contains(document.activeElement)) return;
            setIsOpen(false);
        }

        if (isOpen) {
            window.addEventListener("keydown", onBlur);
            window.addEventListener("click", clickOutside);
        } else {
            window.removeEventListener("click", clickOutside);
            window.removeEventListener("keydown", onBlur);
        }

        return () => {
            window.removeEventListener("click", clickOutside);
            window.removeEventListener("keydown", onBlur);
        };
    }, [dropdownRef, isOpen]);

    return (
        <div className="dropdown" ref={dropdownRef}>
            <div className="dropdown-wrapper">
                <button
                    type="button"
                    className={`dropdown-btn ${isOpen ? "is_active" : ""}`}
                    aria-pressed={isOpen}
                    onClick={() => setIsOpen((v) => !v)}>
                    {buttonContent}
                </button>
                {isOpen && (
                    <div
                        className={`dropdown-content ${placement ? placement : "opacity-0"}`}
                        ref={dropdownContentRef}>
                        {dropdownContent}
                    </div>
                )}
            </div>
        </div>
    );
}

export default Dropdown;
