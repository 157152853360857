import { useContext } from "react";
import { IHeaderProps } from "./Header.interface";
import { AuthenticationContext } from "../../shared/providers/authentication/authentication.provider";
import ProfileCard from "../ProfileCard/ProfileCard";
import LanguagePicker from "../LanguagePicker/LanguangePicker";
import { useLocation } from "react-router-dom";
import { routes } from "../../shared/router/routes";
import { TranslationContext } from "../../shared/providers/translation/translation.provider";

function Header(props: IHeaderProps) {
    const { translate } = useContext(TranslationContext);
    const { user } = useContext(AuthenticationContext);
    const location = useLocation();

    return (
        <header className="header px-3 py-22 d-flex justify-content-between align-items-center">
            {location.pathname === routes.HOME.path && (
                <h1 className="text-h1-smbd">
                    {translate("common.hello", "Hello")}, {user?.email}
                </h1>
            )}
            <div className="d-flex gap-1 ms-auto">
                <LanguagePicker />
                <ProfileCard />
            </div>
        </header>
    );
}

export default Header;
